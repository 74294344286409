import React, { useState } from "react";

export default function ComingSoon() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !email.includes("@")) return alert("Please enter a valid email.");
    console.log("Subscribed email:", email);
    setSubmitted(true);
    setEmail("");
  };

  return (
    <div style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      background: "radial-gradient(circle at top left, #fcd5ce, #fef9c3, #cfefff)",
      padding: "2rem"
    }}>
      <main style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
        <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>capitaldegrant.org.</h1>
        <p style={{ fontSize: "1.125rem", color: "#555" }}>We are a small and growing consulting firm with big ideas.</p>
        <button style={{ padding: "0.5rem 1.5rem", border: "1px solid #000", borderRadius: "4px", background: "transparent", cursor: "pointer" }}>
          LEARN MORE →
        </button>
      </main>

      <footer style={{ width: "100%", maxWidth: "600px", padding: "3rem 0" }}>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "1.5rem", color: "#666" }}>
          <a href="#" aria-label="Facebook">🔵</a>
          <a href="#" aria-label="LinkedIn">🔗</a>
          <a href="#" aria-label="Website">🌐</a>
        </div>

        <p style={{ fontSize: "0.75rem", color: "#999", marginTop: "2rem" }}>
          Copyright © 2025 Capital de Grant - All Rights Reserved. Powered by Tech Sapiens
        </p>
      </footer>
    </div>
  );
}
